import React, {useState,useEffect} from "react";
import { isMobile } from 'react-device-detect'

const JumpPage = ({location}) => {
  useEffect(() => {
    const alias = window.localStorage.getItem('alias')
    if (alias) {
      let finalUrl 
      if (isMobile) {
        finalUrl = `https://${alias}.m${process.env.GATSBY_REDIRECT_DOMAIN}`
      } else {
        finalUrl = `https://${alias}${process.env.GATSBY_REDIRECT_DOMAIN}`
      }
      window.location.href = finalUrl
    }
  },[])           

  return  (
    <div style={{textAlign:'center',display:"flex", justifyContent:'center', alignContent:'center'}}>
    </div>
  )
}


export default JumpPage